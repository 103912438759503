import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Helpers } from '../core/src/helpers';
import { fetchModelPrices, receivePriceFetch } from '../product/components/product-price/model-actions';

const selectModelPrices = Helpers.memoize(
    (prices, productModels, user, returnFailedFetch) => {
        return productModels.reduce((r, p) => {
            let priceObj = prices[p.modelId];
            if (priceObj?.priceFetchFailed && !returnFailedFetch) {
                return r;
            }
            if (user && user.TierPriceMsg && priceObj.tierPrice) {
                priceObj.tierMsg = user.TierPriceMsg;
            }
            r[p.modelId] = priceObj;
            return r;
        }, {});
    },
    (prices, productModels) => {
        return productModels.reduce((r, p) => {
            const price = prices[p.modelId] || {};
            return r.concat(`${p.modelId}-${price?.salePrice}`);
        }, ``);
    }
);

/**
 * @typedef {Object} ProductModel
 * @property {string} modelId - id of the product model
 */

/**
 * @name useModelPrice
 * @param {ProductModel} productModels
 * @returns {Object} - hash map of model prices by modelId
 */
export default function useModelPrice(productModels = [], options) {
    const { returnFailedFetch = false } = options || {};
    const dispatch = useDispatch();
    const modelPrices = useSelector(state => state.productData.productInfo.modelPrices);
    const user = useSelector(state => state.userData);

    useEffect(() => {
        let noModelPrice = productModels.filter(({ modelId }) => {
            const priceFailure = modelPrices[modelId];
            //when prices fai to fetch, we get no response back so we dont know the sku
            // which mean we need to check for fetch fail by itemId
            return modelId && !(modelId in modelPrices) && (!priceFailure || !priceFailure.priceFetchFailed);
        });
        if (noModelPrice.length > 0) {
            const ids = noModelPrice.map(p => p.modelId);
            dispatch(receivePriceFetch(ids));
            dispatch(fetchModelPrices(ids));
        }
    });

    return { modelPrices: selectModelPrices(modelPrices, productModels, user, returnFailedFetch) };
}
