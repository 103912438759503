import React from 'react';
import loadable from '@loadable/component';

import { Helpers } from '../../../core/src/helpers';

const ProductTile = loadable(() => Helpers.retryFunc(() => import('./product-tile')));

export default props => {
    return <ProductTile {...props} />;
};
