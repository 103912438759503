import { removeKebabCase } from 'hpstore-helpers';
/***
 * Search term logic taken from ETR page
 */
export const getSearchTerm = value => {
    //remove trailing parent sku value
    const strValue = (value || '').replace(/(-p-.*--1$)/, '');
    let defaultValue = removeKebabCase({ str: strValue });
    defaultValue = defaultValue
        .replace('All in One', 'all-in-one')
        .replace(/^HP /, '')
        .replace(/ Cartridges($|\s)/, ' ')
        .replace(/ Printheads($|\s)/, ' ')
        .replace(/ Ink($|\s)/, ' ')
        .replace(/\s\s+/g, ' ')
        .trim();
    if (defaultValue.includes('&')) {
        defaultValue = defaultValue.split('&')[0].trim();
    }
    return defaultValue.toLowerCase();
};
