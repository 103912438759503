import React from 'react';

import MDPProductList from './mdp-product';
import CompareDrawer from '../../compare/components/compare-drawer';
import MDPRichContent from './mdp-rich-content';
import MobilePartnerLogo from './mdp-mobile-logos';
import MDPStickyNAV from './sticky-nav';
import SuppliesFinder from './mdp-supplies-search';
import ThemeCSSLoader from './theme-css-loader';
import useTemplateTheme, { WithTheme } from '../../hooks/useTemplateTheme';

import './mdp.less';
import './rich-content.less';

const MDPContent = () => {
    const { tabs } = useTemplateTheme(['tabs']);
    const { order = ['buy', 'features'] } = tabs || {};
    return (
        <>
            {order.map(key => {
                if (key === 'features') {
                    return <MDPRichContent key={key} />;
                } else {
                    return <MDPProductList key={key} tab={key} />;
                }
            })}
        </>
    );
};

const MDP = ({ vanityUrl }) => {
    /** this wrapping div was a fragment, but for some reason the client graphql fallback did not like that **/
    return (
        <div>
            <div className="mdp">
                <WithTheme CSSLoader={ThemeCSSLoader}>
                    <SuppliesFinder />
                    <MDPStickyNAV />
                    <MDPContent />
                    <MobilePartnerLogo />
                </WithTheme>
            </div>
            <CompareDrawer />
        </div>
    );
};

export default MDP;
