import React from 'react';

import FooterLogo from '../../shared/components/footer-logo';
import withPageComponents from '../../page/components/with-page-components';

const MDPFooterLogo = ({ mdpHeader }) => {
    const { logos } = mdpHeader || {};
    if (!logos || logos.length < 1) {
        return null;
    }
    return <FooterLogo logos={logos} breakpoint={1024} />;
};

export default withPageComponents(MDPFooterLogo, { components: ['mdpHeader'] });
