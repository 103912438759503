import React, { useEffect, useState } from 'react';

import useProductPrice, { getSKUByItemId, getPriceAriaLabel } from './useProductPrice';
import useModelPrice from './useModelPrice';
import { withError } from '../shared/components/error-boundary';
import { Helpers } from '../core';

const MAX_INTERVAL = 100;

/***
 * Returns ETR html structure for price elements
 */
const getPriceHtml = price => {
    if (!price) {
        return null;
    }
    const showStrike = price.salePrice < price.regularPrice;
    return `<span class='price-info'>${
        ['CTO', 'category'].includes(price.productType)
            ? '<span id="price_text" aria-hidden="true" style=""> Starting at </span>'
            : ''
    }
    ${
        showStrike
            ? `<span class="price_strike" aria-hidden="true" style="text-decoration: line-through;">${Helpers.formatCurrency(
                  price.regularPrice,
                  '$',
                  true,
              )}</span>`
            : ''
    }
    </span>
    <span class="price_amount" aria-hidden="true">${Helpers.formatCurrency(price.salePrice, '$', true)}</span>`;
};

/***
 * Checks DOM for price elements. Use this for custom html that has these price blocks in them. This is primarily to support
 * the existing ETR functionality that allows content team to input price elements that will be auto populated on page load
 * @name useDynamicPriceHtml
 * @param {[]} dependencies - List of dependencies that should trigger a re-check of pricing
 * @param options - Options object
 * @param options.selectorPrefix Optional css selector prefix used to isolate scanning of DOM
 * @param options.timeout The interval for polling. If it's <= 0 it will not poll
 * @param options.maxAttempts max attempts for polling
 */
export default (dependencies = [], options) => {
    const [elementPrices, setElementPrice] = useState([]);
    const [attempts, setAttempts] = useState(0);
    const [modelElementPrices, setModelElementPrice] = useState([]);
    const { prices } = useProductPrice(elementPrices, { getByItemId: true });
    const { modelPrices } = useModelPrice(modelElementPrices);
    let { selectorPrefix, timeout, maxAttempts = MAX_INTERVAL } = options || {};
    selectorPrefix = selectorPrefix ? `${selectorPrefix} ` : '';
    //set up polling for content that comes in async or is lazy loaded
    useEffect(() => {
        let interval;
        if (timeout > 0) {
            interval = setInterval(() => {
                setAttempts(attempts => {
                    const nextAttempts = attempts + 1;
                    if (nextAttempts > maxAttempts) {
                        clearInterval(interval);
                    }
                    return nextAttempts;
                });
            }, timeout);
        }
        return () => clearInterval(interval);
    }, [timeout, maxAttempts]);

    useEffect(() => {
        try {
            if (typeof document === 'undefined') {
                return () => {};
            }
            //Scan DOM for elements with the data-omni
            const priceNodes = [
                ...(document.querySelectorAll(`${selectorPrefix}[id^="price_holder"][data-omni]`) || []),
            ].reduce((r, e) => {
                try {
                    const data = JSON.parse(e.getAttribute('data-omni'));
                    const sku = getSKUByItemId(data.itemId, prices);
                    if (elementPrices.indexOf(e => e.itemId === data.itemId) < 0 && !(sku in prices)) {
                        r.push(data);
                        //mark item element for price fetch
                        e.setAttribute('data-price', data.itemId);
                    }
                } catch (e) {}
                return r;
            }, []);

            //Scan DOM for elements with the data-ui and model price
            const modelPriceNodes = [
                ...(document.querySelectorAll(`${selectorPrefix}[id^="price_holder_model"][data-ui]`) || []),
            ].reduce((r, e) => {
                try {
                    const data = JSON.parse(e.getAttribute('data-ui'));
                    const { modelId } = data || {};
                    if (modelElementPrices.indexOf(e => e.modelId === data.modelId) < 0 && !(modelId in modelPrices)) {
                        r.push(data);
                        //mark item element for price fetch
                        e.setAttribute('data-model-price', data.modelId);
                    }
                } catch (e) {
                    //
                }
                return r;
            }, []);
            //if new price elements discovered, update list of price elements
            if (priceNodes.length > 0) {
                setElementPrice([...elementPrices, ...priceNodes]);
            }
            if (modelPriceNodes.length > 0) {
                setModelElementPrice([...modelElementPrices, ...modelPriceNodes]);
            }
            //if we have received prices, check for elements we marked to set price
            if (Object.keys(prices).length > 0) {
                [...(document.querySelectorAll(`${selectorPrefix}[data-price]`) || [])].forEach(e => {
                    const itemId = e.getAttribute('data-price');
                    const sku = getSKUByItemId(itemId, prices);
                    if (sku && prices[sku]) {
                        const priceObj = prices[sku];
                        e.removeAttribute('data-price');
                        e.setAttribute('aria-label', getPriceAriaLabel(priceObj));
                        e.classList.add('standard_price');
                        e.innerHTML = getPriceHtml(priceObj);
                    }
                });
            }
            //if we have received prices, check for elements we marked to set price
            if (Object.keys(modelPrices).length > 0) {
                [...(document.querySelectorAll(`${selectorPrefix}[data-model-price]`) || [])].forEach(e => {
                    const modelId = e.getAttribute('data-model-price');
                    if (modelId && modelPrices[modelId]) {
                        const priceObj = modelPrices[modelId];
                        e.removeAttribute('data-model-price');
                        e.setAttribute('aria-label', getPriceAriaLabel(priceObj));
                        e.classList.add('standard_price');
                        e.innerHTML = getPriceHtml(priceObj);
                    }
                });
            }
        } catch (e) {}
    }, [...dependencies, prices, modelPrices, attempts]);
};
