import { useState, useEffect } from 'react';

export const scrollIntoViewWithOffset = (selector, offset) => {
    const element = document.querySelector(selector);
    if (!element) {
        return;
    }
    window.scrollTo({
        behavior: 'smooth',
        top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - offset,
    });
};

/**
 * Hook to track scroll position between targets
 * @name useScrollTarget
 * @param {string} targetHash - id of element that marks the top of the scroll range
 * @param {function onScroll(params) {}} - callback function when scroll
 * @param {number} offset - offset scroll position to adjust calculation
 * @param {number} offset - offset scroll position to adjust calculation
 * @param {function getInRange(params) {}} - option function to allow for custom scroll position targeting
 * @returns {boolean} inRange - returns boolean if scroll position within target range
 */ export default (targetHash, bottomHash, onScroll, offset = 0, getInRange) => {
    const [inRange, setInRange] = useState(false);

    let lastKnownScrollPosition = 0;
    let ticking = false;
    const checkScrollPosition = () => {
        lastKnownScrollPosition = window.scrollY + offset;
        if (!ticking) {
            window.requestAnimationFrame(() => {
                try {
                    ticking = false;
                    let topElem = document.getElementById(targetHash);
                    let bottomElm = bottomHash && document.getElementById(bottomHash);
                    if (getInRange) {
                        setInRange(getInRange(lastKnownScrollPosition, topElem, bottomElm));
                    } else if (
                        topElem &&
                        lastKnownScrollPosition > topElem.offsetTop &&
                        (!bottomElm || bottomElm.offsetTop > lastKnownScrollPosition)
                    ) {
                        !inRange && setInRange(true);
                    } else {
                        inRange && setInRange(false);
                    }
                    onScroll && onScroll(lastKnownScrollPosition);
                } catch (e) {}
            });
            ticking = true;
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', checkScrollPosition);
        return () => {
            window.removeEventListener('scroll', checkScrollPosition);
        };
    }, [inRange, setInRange]);

    return inRange;
};
