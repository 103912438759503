import React, { useState, useEffect } from 'react';

/**
 * Sequentially loads provided scripts
 * @param {[]} resourceUrl
 * @returns
 */
const importScripts = (resourceUrl = []) => {
    const [scripts, setScripts] = useState([]);
    const [scriptLoaded, setScriptsLoaded] = useState(0);
    useEffect(() => {
        if (scriptLoaded < resourceUrl.length && resourceUrl[scriptLoaded]) {
            const script = document.createElement('script');
            script.src = resourceUrl[scriptLoaded];
            script.async = true;
            document.body.appendChild(script);
            script.onload = () => {
                setScriptsLoaded(scriptLoaded + 1);
            };
            script.onerror = () => {
                setScriptsLoaded(scriptLoaded + 1);
            };
            setScripts(scripts.concat([{ scriptElement: script }]));
        }
        return () => {
            /* TODO: Need to figure out a better way to handle removing this scripts, also dont know if we need to  
            try {
                scripts.forEach(({ scriptElement }) => {
                    document.body.removeChild(scriptElement);
                });
            } catch (e) {}*/
        };
    }, [resourceUrl, scriptLoaded]);

    return [scriptLoaded === resourceUrl.length];
};

const NULL_STATE = undefined;
/**
 * Loads external scripts and css and renders child components once finished
 */
export default ({ js = [], css = [], sequentialLoad = true, skip, children, placeholder }) => {
    const [scriptsLoaded] = importScripts(js);
    return (
        <>
            {css.map(src => (
                <link rel="stylesheet" type="text/css" href={src} />
            ))}
            {scriptsLoaded || skip ? children : placeholder}
        </>
    );
};
