import React, { useEffect, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { getColors } from '../product/get-product-props';
import { StoreAppAPI } from '../core/src/storeapp-api-client';

import { Helpers } from '../core/src/helpers';

const storeAppAPI = new StoreAppAPI();

const getHexColorMap = Helpers.memoize(() => {
    return storeAppAPI.component.getHexColorMap();
});

export const getColorSelection = (product, selectedIndex, hexColorMap, onChange) => {
    const colorCTOs = getColorCTOs(product);
    if (!colorCTOs || colorCTOs.length < 1) {
        return {};
    }
    const index = selectedIndex || colorCTOs.findIndex(p => p.default === 'yes');
    return {
        colorSelection: {
            colors: getColors(colorCTOs, undefined, hexColorMap),
            activeIndexes: [index],
            onChange: (indices, index) => {
                onChange && onChange(product, index);
            },
            size: 'small',
        },
    };
};

const getColorCTOs = product => {
    //handle format for ETR and StoreApp
    const { colorCTOs, attributes } = product || {};
    const { colorCTOs: colorCTOsAttr } = attributes || {};
    return colorCTOs || colorCTOsAttr;
};

const hasCTOColors = product => {
    return (getColorCTOs(product) || []).length > 0;
};

/**
 * @typedef {Object} Product
 * @property {string} sku - product SKU
 * @property {string} itemId - legacy id of product used to fetch price
 * @property {string} catentryId - new id for fetching product price
 * @property {Array.<Product>} colorCTOs - list of color options
 */

/**
 * @name useColorSelector
 * @param {Array.<Product>} products
 * @returns {object} - Product color selection hash map by sku
 */
export default function useColorSelector(products = []) {
    const [colorSelectors, setColorSelectors] = useState({});
    const [hexMap, setHexMap] = useState();
    const stateRef = useRef();
    // make stateRef always have the current colorSelectors to be used by onChange function
    stateRef.current = colorSelectors;
    const onChange = useCallback((product, index) => {
        try {
            const newState = { ...stateRef.current };
            const currentSkuSelection = newState[product.sku];
            currentSkuSelection.colorSelection.activeIndexes = [index];
            setColorSelectors(newState);
        } catch (e) {
            console.error(`Caught: ${e}`);
        }
    });

    useEffect(() => {
        let isMounted = true;
        const ctoColorSKUs = products.filter(p => hasCTOColors(p) > 0 && !(p.sku in colorSelectors));
        if (ctoColorSKUs.length > 0 && !hexMap) {
            getHexColorMap().then(value => {
                isMounted && setHexMap(value);
            });
        } else if (ctoColorSKUs.length > 0) {
            setColorSelectors({
                ...colorSelectors,
                ...ctoColorSKUs.reduce((r, p) => {
                    r[p.sku] = getColorSelection(p, undefined, hexMap, onChange);
                    return r;
                }, {}),
            });
        }
        return () => {
            isMounted = false;
        };
    }, [products, hexMap]);

    return [colorSelectors, (product, selection) => {}];
}

/* Component that uses render props with the useColorSelector hook to help isolate re-rendering  */
const ColorSelectorContainer = ({ children, product }) => {
    const { sku } = product || {};
    const [colorSelectors] = useColorSelector([product]);
    const { colorSelection } = colorSelectors[sku] || {};
    return <>{children({ colorSelection })}</>;
};

ColorSelectorContainer.propTypes = {
    children: PropTypes.func.isRequired,
    product: PropTypes.object.isRequired,
};

export { ColorSelectorContainer };
