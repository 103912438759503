import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../page/components/with-page-components';
import { getSearchTerm } from '../util';
import { Helpers } from '../../core/src/helpers';

const SuppliesFinder = loadable(() => Helpers.retryFunc(() => import('../../supplies-finder')));

const MDPElicitSearch = ({ vanityUrl, location, analyticsData, seo }) => {
    let { isEspotOverride: showElicit } = analyticsData || {};
    if (!showElicit && !/^mdp\/(ink\-cartridges|toner\-cartridges|ink\-toner)/i.test(vanityUrl)) {
        return null;
    }
    const { h1Tag, title } = seo || {};
    const [country, shop, dir, slug, slugTwo] = location.pathname.replace(/^\//, '').split('/');
    let defaultValue = getSearchTerm(h1Tag || title || slugTwo);
    return (
        <SuppliesFinder
            header="HP INK, TONER AND PAPER FINDER"
            disableIcon
            className="elicit-dark"
            defaultSearch={defaultValue}
        />
    );
};

export default withPageComponents(MDPElicitSearch, { location: true, analyticsData: true, seo: true });
