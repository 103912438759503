import React from 'react';
import loadable from '@loadable/component';
import { withError } from '../../../shared/components/error-boundary';

export default withError(({ css }) => {
    if (!css) return null;
    if (css === 'omen-brand') {
        import('./mdp-omen-brand/mdp-omen-brand.less');
    }
    /* const CSS = loadable(() => import(`./${css}`));
    return <CSS css={css} />;*/
});
