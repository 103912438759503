import React from 'react';

import ProductTile from '../../product/components/product-tile';
import withPageComponents from '../../page/components/with-page-components';
import useAddToCart from '../../hooks/useAddToCart';
import useProductCompare from '../../hooks/useProductCompare';
import getProductProps from '../../product/get-product-props';
import useProductPrice from '../../hooks/useProductPrice';
import TitleSection from '../../shared/components/title-section';
import useProductImpressions from '../../hooks/useProductImpressionsV2';
import useCartState from '../../hooks/useCartState';
import { ColorSelectorContainer } from '../../hooks/useColorSelector';
import useTemplateTheme from '../../hooks/useTemplateTheme';

const DEFAULT_LAZYLOAD_SETTINGS = {
    robots: true,
    skip: true,
    offset: 800,
};

const applyThemeProps = (productProps, css, buttonProps) => {
    if (!productProps || !css) {
        return productProps;
    }
    try {
        const { priceProps } = productProps;
        const themeProps = {
            ...(productProps || {}),
            ...(productProps.modalProps
                ? {
                      modalProps: {
                          ...productProps.modalProps,
                          ...{ className: css ? `${css}-modal-link` : '' },
                      },
                  }
                : {}),
            ...(productProps.productLinkProps
                ? {
                      productLinkProps: {
                          ...productProps.productLinkProps,
                          ...{ className: css ? `${css}-view-details` : '' },
                      },
                  }
                : {}),
            ...(buttonProps && productProps.purchaseBtnProps
                ? {
                      purchaseBtnProps: {
                          ...productProps.purchaseBtnProps,
                          ...buttonProps,
                      },
                  }
                : {}),
            ...(productProps.product && css
                ? {
                      product: {
                          ...productProps.product,
                          ...(productProps.product.productTypeClassName
                              ? {
                                    ...{
                                        productTypeClassName:
                                            productProps.product.productTypeClassName + css ? ` ${css}-prd-type` : '',
                                    },
                                }
                              : {
                                    ...{ productTypeClassName: css ? `${css}-prd-type` : '' },
                                }),
                      },
                  }
                : {}),
            ...(priceProps && css
                ? {
                      priceProps: {
                          ...priceProps,
                          ...(priceProps.bundle ? { mainPriceClassName: css ? `${css}-bundle-price` : '' } : {}),
                      },
                  }
                : {}),
        };

        return themeProps;
    } catch (err) {
        return productProps;
    }
};

const MdpProductList = ({ mdpProductList, mdpHeader, analyticsData, lazyLoadSettings = DEFAULT_LAZYLOAD_SETTINGS }) => {
    const { products = [], sectionTitle: title } = mdpProductList || {};
    const { prices } = useProductPrice(products, { useSubscriptionsPromo: true });
    const addToCart = useAddToCart(true);
    const compare = useProductCompare();
    const { cartId } = useCartState();
    const { css, tabs, primaryButton } = useTemplateTheme(['tabs', 'primaryButton']);
    const { showHeaderOnFeatureTab } = tabs || {};

    let { page_level } = analyticsData || {};
    const productsWithAnalytics = useProductImpressions({
        products,
        list: page_level,
        componentKey: 'mdpProductList',
    });

    const parseProductProps = productProps => {
        return productProps
            ? {
                  ...(productProps || {}),
                  ...(productProps.modalProps
                      ? {
                            modalProps: {
                                ...productProps.modalProps,
                                ...{ modalLinkClassName: css ? `${css}-modal-link` : '' },
                            },
                        }
                      : {}),
                  ...(productProps.productLinkProps
                      ? {
                            productLinkProps: {
                                ...productProps.productLinkProps,
                                ...{ productLinkClassName: css ? `${css}-view-details` : '' },
                            },
                        }
                      : {}),
                  ...(productProps.purchaseBtnProps
                      ? {
                            purchaseBtnProps: {
                                ...productProps.purchaseBtnProps,
                                ...{ color: css ? 'dark' : '' },
                            },
                        }
                      : {}),
                  ...(productProps.product
                      ? {
                            product: {
                                ...productProps.product,
                                ...(productProps.product.productTypeClassName
                                    ? {
                                          ...{
                                              productTypeClassName:
                                                  productProps.product.productTypeClassName + css
                                                      ? ` ${css}-prd-type`
                                                      : '',
                                          },
                                      }
                                    : {
                                          ...{ productTypeClassName: css ? `${css}-prd-type` : '' },
                                      }),
                            },
                        }
                      : {}),
              }
            : {};
    };

    if (productsWithAnalytics && productsWithAnalytics.length > 0) {
        return (
            <div id="buy">
                {!showHeaderOnFeatureTab && (
                    <TitleSection title={title} partnerLogoProps={mdpHeader} typographyVariant={'titleM'} />
                )}
                <div className="mdpList">
                    {productsWithAnalytics.map((product, idx) => {
                        const { sku } = product;
                        return (
                            <ColorSelectorContainer key={`${sku}-${idx}`} product={product}>
                                {({ colorSelection }) => (
                                    <ProductTile
                                        {...applyThemeProps(
                                            getProductProps(
                                                product,
                                                prices[sku],
                                                { addToCart, compare, colorSelection },
                                                { resizeImage: true, titleTag: 'h2', hasPriceSeoText: true },
                                                { list: 'mdp', position: idx + 1, cartId },
                                            ),
                                            css,
                                            primaryButton,
                                        )}
                                        isFull
                                        className={css ? `tile ${css}-product-tile` : 'tile'}
                                        ratingSpaceReserved={true}
                                        lazyLoadSettings={idx > 2 && lazyLoadSettings}
                                        classes={{
                                            offerMessage: css ? `${css}-offer-msg` : '',
                                        }}
                                    />
                                )}
                            </ColorSelectorContainer>
                        );
                    })}
                </div>
            </div>
        );
    }
    return null;
};

export default withPageComponents(MdpProductList, { components: ['mdpProductList', 'mdpHeader'], analyticsData: true });
